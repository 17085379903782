import groq from 'groq'
import { asRouteMap } from '@kaliber/routing'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { toPlainText } from '@portabletext/react'
import dayjs from 'dayjs'
import cookie from 'cookie'

const { extract, language, slug } = parameterExtraction

export const snippets = createSnippets()
const routeData = getRouteData()

export const RESULTS_PER_PAGE = 10
const DELIMITER = '||'

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',
      v1: {
        path: 'v1',
        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        search: {
          path: 'search',
          data: requestHandlers => requestHandlers.search
        },

        ip: {
          path: 'ip',
          data: requestHandlers => requestHandlers.getIpAddress
        },
        notFoundItems: {
          path: 'notfounditems',
          data: requestHandlers => requestHandlers.notFoundItems,
        },
        exlude: {
          path: 'excludefromstats',
          data: requestHandlers => requestHandlers.setExcludeCookie
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,
      home: {
        path: '',
        data: routeData.app.home.data,
      },
      expertises: {
        path: {
          en: 'expertises',
          nl: 'expertises',
        },
        data: routeData.app.expertises.data,
        index: {
          path: '',
          data: routeData.app.expertises.index.data,
        },
        expertise: {
          path: ':slug',
          data: routeData.app.expertises.expertise.data,
        }
      },
      services: {
        path: {
          en: 'services',
          nl: 'diensten',
        },
        data: routeData.app.services.data,
        index: {
          path: '',
          data: routeData.app.services.index.data,
        },
        service: {
          path: ':slug',
          data: routeData.app.services.service.data,
        }
      },

      solutions: {
        path: {
          en: 'solutions',
          nl: 'oplossingen',
        },
        data: routeData.app.solutions.data,
        index: {
          path: '',
          data: routeData.app.solutions.index.data,
        },
        solution: {
          path: ':slug',
          data: routeData.app.solutions.solution.data,
        }
      },

      industries: {
        path: {
          en: 'industries',
          nl: 'sectoren',
        },
        data: routeData.app.industries.data,
        index: {
          path: '',
          data: routeData.app.industries.index.data,
        },
        industry: {
          path: ':slug',
          data: routeData.app.industries.industry.data,
        }
      },

      inspirations: {
        path: {
          en: 'inspiration',
          nl: 'inspiratie'
        },
        data: routeData.app.inspirations.data,
        index: {
          path: '',
          data: routeData.app.inspirations.index.data,
        },
        inspiration: {
          path: ':slug',
          data: routeData.app.inspirations.inspiration.data,
        }
      },
      careers: {
        path: {
          en: 'careers',
          nl: 'werken-bij'
        },
        data: routeData.app.careers.data,
        index: {
          path: '',
          data: routeData.app.careers.index.data,
        },
        careersPage: {
          path: ':slug',
          data: routeData.app.careers.careersPage.data
        }
      },
      jobs: {
        path: {
          en: 'jobs',
          nl: 'banen'
        },
        data: routeData.app.jobs.data,
        index: {
          path: '',
          data: routeData.app.jobs.index.data,
        },
        job: {
          path: ':slug',
          data: routeData.app.jobs.job.data,
        }
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      cookiePolicy: {
        path: {
          en: 'cookie-policy',
          nl: 'cookie-beleid'
        },
        data: routeData.app.cookiePolicy.data,
      },
      notFound: {
        path: '*',
      },
    },
    admin: 'admin',
  },
  { trailingSlash: true }
)

function isExcludedFromTracking({ excludedIps, query, headers, ip } ) {
  const hasExcludeFromTrackingCookie  = cookie.parse(headers?.cookie || '').exclude_gtm
  const excludeFromTracking = Boolean(
    hasExcludeFromTrackingCookie
    || query?.exclude_gtm
    || excludedIps?.some(x => x === ip)
  )
  return { excludeFromTracking, hasExcludeFromTrackingCookie }
}

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: groq`*[_id == 'settings'] | order(_updatedAt desc) [0]`,
          footer: [groq`*[_type == 'footer' && language == $language] | order(_updatedAt desc) [0] {
            legalItems[] {
              _type == 'internalLink' => ${snippets.internalLink},
            },
            navItems[] ${snippets.menuItem}
          }`, { language }],
          menu: [
            groq`*[_type == 'menu' && language == $language] | order(_updatedAt desc) [0] {
              contact ${snippets.internalLink},
              items[] ${snippets.menuItem}
            }`,
            { language },
          ],
          excludedIpAddresses: [groq`*[_type == 'excludedIpAddress'].ipAddress`]
        }),
        derived: ({ data, headers, query, ip }) => ({ breadcrumbs: [],
          ...isExcludedFromTracking({ excludedIps: data.excludedIpAddresses, query, headers, ip }),
          excludedIpAddresses: []
        }),
      },

      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('home', snippets.templates.generic.fields, { language }),
          }),
          derived: snippets.templates.generic.derived,
          extractParams: { home: extract(language) },
        },
      },

      expertises: {
        data: deriveBreadcrumb('expertises'),
        index: {
          data: {
            groq: ({ params: { language } }) => ({
              doc: snippets.singletonDocument('expertises', snippets.templates.generic.fields, { language }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { expertises: extract(language) },
          },
        },
        expertise: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('expertise', snippets.templates.generic.fields, { language, slug }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { expertise: extract(language, slug) },
          },
        },
      },

      services: {
        data: deriveBreadcrumb('services'),
        index: {
          data: {
            groq: ({ params: { language } }) => ({
              doc: snippets.singletonDocument('services', snippets.templates.generic.fields, { language }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { services: extract(language) },
          },
        },
        service: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('service', snippets.templates.generic.fields, { language, slug }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { service: extract(language, slug) },
          },
        },
      },

      solutions: {
        data: deriveBreadcrumb('solutions'),
        index: {
          data: {
            groq: ({ params: { language } }) => ({
              doc: snippets.singletonDocument('solutions', snippets.templates.generic.fields, { language }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { solutions: extract(language) },
          },
        },
        solution: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('solution', snippets.templates.generic.fields, { language, slug }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { solution: extract(language, slug) },
          },
        },
      },

      industries: {
        data: deriveBreadcrumb('industries'),
        index: {
          data: {
            groq: ({ params: { language } }) => ({
              doc: snippets.singletonDocument('industries', snippets.templates.generic.fields, { language }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { industries: extract(language) },
          },
        },
        industry: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('industry', snippets.templates.generic.fields, { language, slug }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { industry: extract(language, slug) },
          },
        },
      },

      inspirations: {
        data: deriveBreadcrumb('inspirations'),
        index: {
          data: {
            fetch: {
              elasticsearchResults: requestHandlers => requestHandlers.search
            },
            groq: ({ params: { language } }) => {
              return {
                doc: snippets.singletonDocument('inspirations', snippets.templates.inspirations.fields, { language }),
                inspirationTags: snippets.inspirationTags({ language })
              }
            },
            derived: snippets.templates.inspirations.derived,
            extractParams: { inspirations: extract(language) },
          },
        },
        inspiration: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('inspiration', snippets.templates.inspiration.fields, { language, slug }),
            }),
            derived: snippets.templates.inspiration.derived,
            extractParams: { inspiration: extract(language, slug) },
          },
        }
      },

      careers: {
        data: deriveBreadcrumb('careers'),
        index: {
          data: {
            groq: ({ params: { language } }) => ({
              doc: snippets.singletonDocument('careers', snippets.templates.generic.fields, { language }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { careers: extract(language) },
          },
        },
        careersPage: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('careersPage', snippets.templates.generic.fields, { language, slug }),
            }),
            derived: snippets.templates.generic.derived,
            extractParams: { careersPage: extract(language, slug) },
          },
        },
      },

      jobs: {
        data: deriveBreadcrumb('expertises'),
        index: {
          data: {
            groq: ({ params: { language } }) => {
              return {
                doc: snippets.singletonDocument('jobs', snippets.templates.jobs.fields, { language }),
                jobs: snippets.overviewItems(
                  'job',
                  snippets.templates.job.overviewItemFields,
                  { language }
                ),
              }
            },
            derived: snippets.templates.jobs.derived,
            extractParams: { jobs: extract(language) },
          },
        },
        job: {
          data: {
            groq: ({ params: { language, slug } }) => ({
              doc: snippets.document('job', snippets.templates.job.fields, { language, slug }),
            }),
            derived: snippets.templates.job.derived,
            extractParams: { job: extract(language, slug) },
          },
        }
      },

      page: {
        data: {
          groq: ({ params: { language, slug } }) => ({
            doc: snippets.document('page', snippets.templates.generic.fields, { language, slug }),
          }),
          derived: snippets.templates.generic.derived,
          extractParams: { page: extract(language, slug) },
        },
      },

      cookiePolicy: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument('cookiePolicy', snippets.templates.cookiePolicy.fields, { language }),
          }),
          derived: snippets.templates.cookiePolicy.derived,
          extractParams: { cookiePolicy: extract(language) },
        },
      },
    }
  }
}

function createSnippets() {
  const dataLayerFields = groq`_id, _type, _createdAt, _updatedAt`
  const linkableDocumentFields = groq`_id, _type, language, slug { current }`
  const externalLink = groq`{..., 'target': '_blank'}` //is set via a markdef on a span via structured data, so have to use ...
  const internalLink = groq`{..., ref->{${linkableDocumentFields}, title}}` //is set via a markdef on a span via structured data, so have to use ...
  const internalOrExternalLink = groq`{
    _type == 'internalLink' => ${internalLink},
    _type == 'externalLink' => ${externalLink},
    _type == 'inspirationOverviewLink' => {
      ...,
      inspirationTags[]->{ slug { current }},
      contentTypes[]
    }
  }`
  const button = groq`{buttonLabel, internalOrExternalLink[] ${internalOrExternalLink}}`
  const employee = groq`{_id, _type, firstName, lastName, image, jobTitle, mobile, phone, email, linkedIn, hubspot, image}`
  const contact = groq`{employee->${employee}}`
  const blocks = groq`{..., markDefs[] ${internalOrExternalLink}}` // We need all structured content here
  const imageWithText = groq`{blocks[] ${blocks}, position, button ${button}, image}`
  const sliderWithMedia = groq`{images[]}`
  const vimeo = groq`{title, url}`

  const faq = groq`{
    title,
    description,
    button ${button},
    questions[]->{
      _key,
      question,
      answer[] {
        ..., // We need all structured content here
        _type == 'button' => ${button},
        markDefs[] ${internalOrExternalLink},
        _type == 'image' => image,
      }
    }
  }`

  const hero = groq`{
    title,
    tags,
    image,
    company->{companyName, isCharity, logo},
    button ${button}
  }`

  const inspirationHero = groq`{
    title,
    subtitle,
    tags,
    image,
    company->{companyName, isCharity, logo},
    button ${button}
  }`

  const menuItem = groq`{
    _type == 'externalLink' => ${externalLink},
    _type == 'internalLink' => ${internalLink},
    _type == 'subMenu' => {
      label,
      items[] ${internalOrExternalLink},
    }
  }`

  const sliderWithPages = groq`{
    title,
    items[] {
      backgroundColor,
      ref->{
        intro,
        ${linkableDocumentFields},
        title,
        backgroundColor,
        hero ${hero},
      }
    }
  }`


  const sliderWithCompanies = groq`{
    sectorTypeToShow,
    'companies': select(
      defined(sectorTypeToShow) => *[_type == 'company' && ^.sectorTypeToShow._ref in sector[]._ref && language == $language]{ type, companyName, logo },
      *[_type == 'company' && type == ^.businessTypeToShow && language == $language]{ type, companyName, logo }
    )
  }`

  const cardsWithCta = groq`{
    title,
    items[] {
      image,
      title,
      description,
      button ${button},
    }
  }`

  function notFound() {
    return groq`
    *[_type=='notFound' && language==$language][0]
    {notFoundItems${sliderWithPages}}
    `
  }

  function translations(projection) {
    return groq`
      *[
        _type == ^._type &&
        translationId == ^.translationId &&
        language != ^.language
      ]
      ${projection}
    `
  }

  return {
    internalLink,
    menuItem,
    notFound,
    singletonDocument(type, fields, { language }) {
      return [
        groq`*[_type == $type && language == $language] | order(_updatedAt desc) [0] ${fields}`,
        { type, language }
      ]
    },
    document(type, fields, { language, slug }) {
      return [
        groq`*[_type == $type && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] ${fields}`,
        { type, language, slug }
      ]
    },
    overviewItems(type, fields, { language }) {
      return [
        groq`*[_type == $type && language == $language] | order(publicationDate desc) [] ${fields}`,
        { type, language }
      ]
    },
    inspirationTags({ language }) {
      return [
        groq`*[_type == 'inspirationTag' && language == $language] | order(title asc)
        {
          title,
          slug { current },
          'count': count(*[_type == 'inspiration' && references(^._id)])
        }`,
        { language }
      ]
    },
    templates: {
      generic: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          themeColor,
          services[],
          hero ${hero},
          intro,
          content[] {
            ..., // We need all structured content here
            _type == 'contact' => ${contact},
            _type == 'imageWithText' => ${imageWithText},
            _type == 'button' => ${button},
            _type == 'sliderWithMedia' => ${sliderWithMedia},
            _type == 'sliderWithPages' => ${sliderWithPages},
            _type == 'sliderWithCompanies' => ${sliderWithCompanies},
            _type == 'faq' => ${faq},
            _type == 'vimeo' => ${vimeo},
            _type == 'cardsWithCta' => ${cardsWithCta},
            markDefs[] ${internalOrExternalLink},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          const { doc } = data || {}

          const additionalDerivedData = getFaqQuestions({ doc })

          return derive({ additionalDerivedData })({ data, derived, params, route })
        },
      },
      inspiration: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          author->${employee},
          publicationDate,
          readTime,
          typeInspiration,
          funnelPhase,
          personas[],
          services[],
          topics[],
          hero ${inspirationHero},
          tags[]->,
          themeColor,
          intro,
          content[] {
            ..., // We need all structured content here
            _type == 'contact' => ${contact},
            _type == 'imageWithText' => ${imageWithText},
            _type == 'button' => ${button},
            _type == 'sliderWithMedia' => ${sliderWithMedia},
            _type == 'sliderWithPages' => ${sliderWithPages},
            _type == 'sliderWithCompanies' => ${sliderWithCompanies},
            _type == 'faq' => ${faq},
            _type == 'vimeo' => ${vimeo},
            _type == 'cardsWithCta' => ${cardsWithCta},
            markDefs[] ${internalOrExternalLink},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          const { doc } = data || {}
          const { hero, author, publicationDate } = doc || {}

          const additionalDerivedData = {
            ...getFaqQuestions({ doc }),
            article: {
              title: hero?.title,
              subtitle: hero?.subtitle,
              author: {
                name: `${author?.firstName || ''} ${author?.lastName || ''}`.trim(),
                url: author?.linkedIn,
              },
              publicationDate,
              ...(hero?.image && { image: hero.image }),
            },
            dataLayer: {
              metadata: {
                content: {
                  dateset: dayjs(doc?.publicationDate).utc().format('YYYY-MM-DD'),
                  subtitle: hero?.subtitle,
                  author: author?.firstName,
                  phase: doc?.funnelPhase ?? '',
                  persona: doc?.personas?.sort()?.join(DELIMITER),
                  topic: doc?.topics?.sort()?.join(DELIMITER),
                },
              },
            },
          }

          return derive({ additionalDerivedData })({ data, derived, params, route })
        },
        overviewItemFields: groq`{
          ${linkableDocumentFields},
          'title': hero.title,
          intro,
          readTime,
          typeInspiration,
        }`,
      },
      inspirations: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          intro,
          themeColor,
          hero ${hero},
          content[] {
            ..., // We need all structured content here
            _type == 'contact' => ${contact},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          return derive({})({ data, derived, params, route })
        }
      },
      job: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          intro,
          themeColor,
          hours,
          author->${employee},
          hero ${hero},
          tags[]->,
          publicationDate,
          content[] {
            ...,
            _type == 'contact' => ${contact},
            _type == 'imageWithText' => ${imageWithText},
            _type == 'button' => ${button},
            _type == 'sliderWithMedia' => ${sliderWithMedia},
            _type == 'sliderWithPages' => ${sliderWithPages},
            _type == 'faq' => ${faq},
            _type == 'vimeo' => ${vimeo},
            markDefs[] ${internalOrExternalLink},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          const { doc } = data || {}
          const blocks = toPlainText(doc?.content?.filter(x => x._type === 'block') || [])

          const additionalDerivedData = {
            ...getFaqQuestions({ doc }),
            job: {
              title: doc?.hero?.title,
              description: `${doc?.intro} ${blocks}`,
              publicationDate: dayjs(doc?.publicationDate).utc().format('YYYY-MM-DD'),
              path: `${route(params)}?utm_campaign=vacatures&utm_medium=organic&utm_source=google_jobs_apply`,
            },
            dataLayer: {
              metadata: {
                content: {
                  dateset: dayjs(doc?.publicationDate).utc().format('YYYY-MM-DD'),
                }
              }
            }
          }

          return derive({ additionalDerivedData })({ data, derived, params, route })
        },
        overviewItemFields: groq`{
          ${linkableDocumentFields},
          'title': hero.title,
          intro,
          hours,
        }`,
      },
      jobs: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          intro,
          themeColor,
          hero ${hero},
          content[] {
            ..., // We need all structured content here
            _type == 'contact' => ${contact},
            _type == 'sliderWithPages' => ${sliderWithPages},
            _type == 'sliderWithCompanies' => ${sliderWithCompanies},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          return derive({})({ data, derived, params, route })
        },
      },
      cookiePolicy: {
        fields: groq`{
          ${dataLayerFields},
          slug { current },
          title,
          content[] {
            ...,
            _type == 'contact' => ${contact},
            markDefs[] ${internalOrExternalLink},
          },
          seo,
          'translations': ${translations(groq`{ ${linkableDocumentFields} }`)}
        }`,
        derived: ({ data, derived, params, route }) => {
          const additionalDerivedData = {
            dataLayer: {
              metadata: {
                content: {
                  id: 'cookiePolicy',
                },
                template: {
                  type: 'cookiePolicy',
                }
              }
            }
          }

          return derive({ additionalDerivedData })({ data, derived, params, route })
        },
      },
    },
  }
}

function derive(
  {
    additionalDerivedData = undefined,
    title = (x, { data }) => x.title,
    description = (x, { data }) => x.intro?.slice(0, 155),
    shareImage = (x, { data }) => data.settings?.shareImage,
  } = {}
) {
  return ({
    data,
    derived = { dataLayer: {} },
    params,
    route,
  }) => {
    const { doc } = data
    if (!doc) return

    const context = { data }

    return {
      ...additionalDerivedData,
      ...derived,
      doc: docWithSeo(doc, {
        title: title(doc, context),
        description: description(doc, context),
        social: {
          shareImage: shareImage(doc, context)
        }
      }),
      dataLayer: {
        ...additionalDerivedData?.dataLayer,
        ...derived.dataLayer,
        metadata: {
          ...additionalDerivedData?.dataLayer?.metadata,
          ...derived.dataLayer?.metadata,
          content: {
            ...additionalDerivedData?.dataLayer?.metadata?.content,
            ...derived.dataLayer?.metadata?.content,
            title: title(doc, context),
            client: doc.hero?.company?.companyName,
            id: doc._id ?? additionalDerivedData?.dataLayer?.metadata?.content?.id,
            type: doc.typeInspiration ?? doc._type,
            service: doc.services?.sort()?.join(DELIMITER),
            language: params?.language,
            datecreated: dayjs(doc._createdAt).utc().format('YYYY-MM-DD'),
            dateupdated: dayjs(doc._updatedAt).utc().format('YYYY-MM-DD'),
            wordcount: [].concat(
              (doc.intro ?? '').split(' '),
              toPlainText(doc.content ?? []).split(' '),
            ).length,
          },
          template: {
            ...additionalDerivedData?.dataLayer?.metadata?.template,
            ...derived.dataLayer?.metadata?.template,
            type: doc._type ?? additionalDerivedData?.dataLayer?.metadata?.template?.type,
            style: doc.themeColor ?? 'random',
          },
        },
      },
      ...addBreadcrumb({ derived, params, route, title: title(doc, context) }),
    }
  }
}

function getFaqQuestions({ doc }) {
  const faq = doc?.content?.filter(x => x._type === 'faq')
  const questions = [].concat(...(faq?.map(x => x.questions) || []))

  return questions.length && {
    faqQuestions: {
      questions: questions.filter(x => x?.answer && x?.question)
    }
  }
}

function addBreadcrumb({ derived, route, params, title }) {
  return {
    breadcrumbs: derived.breadcrumbs.concat({
      title,
      path: route(params),
    })
  }
}

function deriveBreadcrumb(type) {
  return {
    groq: ({ route, params: { language } }) => ({
      [getKey(route)]: [
        groq`*[_type == '${type}' && language == $language] | order(_updatedAt desc) [0] {
          title
        }`,
        { language }
      ],
    }),
    derived: ({ data, derived, params, route }) => (
      data[getKey(route)] &&
      addBreadcrumb({ derived, route, params, title: data[getKey(route)].title })
    ),
  }

  function getKey(route) {
    return route.toString().replace(/./g, '_')
  }
}
